
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
}

html, body, #root {
    font-size: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}


li{
    list-style: none;
}

.nav-items {
        text-align: center;
        list-style: none;
        text-decoration: none;
        color:#dc1111
}
.nav-Link-item {
    text-decoration: none;
    color: rgb(15, 12, 12);
    margin-right: 25px;
}

.menu-link{
    grid-column: 4/5;
}

.menu-link ul {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

footer {
    background-color: black;
    color:white;

    margin-top: auto;
}

/* Create a flex container to arrange the header, content, and footer vertically */
.main-app-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex:1;
    position: relative;
    min-height: 100vh;
  }
.content-area {
    flex-grow: 1;
    background-color: #fefaed;
}
.main-body-container{
    padding-bottom: 2.5rem;    /* Footer height */
    background-color: #fefaed;
}
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;            /* Footer height */
}

input {
    border-color: '#d1d1d6';
    border-radius:'10px';
    margin-bottom: 10px;
    padding: 5px;
}

.enquiry-container {
    display: 'flex' !important; 
    justify-content:'space-around' !important;
    color:rgb(0, 255, 102);
    padding: 10px;
}

button {
    width: 100px;
    padding: 5px;
    border-radius:'5px';
    background: black;
    color: white;
}






.main-nav {
    width: 100%;
    height: 5rem;
    display: grid;
    grid-template-columns: 10rem 1fr 1fr 4fr 1rem;
    box-shadow: 5px 0px 20px 5px #ebe6d9;
    background: #fefaed;
}

.logo {
    display: grid;
    grid-column: 1/3;
    justify-content: start;
    align-items: center;
}

footer {
    background-color: black;
    color:white;
    margin-top: auto;
}

.address-input {
    width: 200px;
}

.sample {
    border: none;
    float: right;
    align-items: center;
    margin-top: 20px;
}


/* Media Query for Contact page*/
@media screen and (max-width: 768px) {
    #contactPage-mainSection {
      flex-direction: 'column';
    }

    #section-left{
        width: '100%';
    }
    #section-right{
        width: '100%';
    }
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Jost, sans-serif;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

li {
  list-style: none;
}

.nav-items {
  text-align: center;
  color: #dc1111;
  text-decoration: none;
  list-style: none;
}

.nav-Link-item {
  color: #0f0c0c;
  margin-right: 25px;
  text-decoration: none;
}

.menu-link {
  grid-column: 4 / 5;
}

.menu-link ul {
  justify-content: flex-end;
  align-items: center;
  height: 5rem;
  display: flex;
}

.main-app-container {
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
}

.content-area {
  background-color: #fefaed;
  flex-grow: 1;
}

.main-body-container {
  background-color: #fefaed;
  padding-bottom: 2.5rem;
}

#root {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

.footer {
  width: 100%;
  height: 2.5rem;
  position: absolute;
  bottom: 0;
}

input {
  border-color: "#d1d1d6";
  border-radius: "10px";
  margin-bottom: 10px;
  padding: 5px;
}

.enquiry-container {
  color: #0f6;
  padding: 10px;
  display: "flex" !important;
  justify-content: "space-around" !important;
}

button {
  border-radius: "5px";
  color: #fff;
  background: #000;
  width: 100px;
  padding: 5px;
}

.main-nav {
  background: #fefaed;
  grid-template-columns: 10rem 1fr 1fr 4fr 1rem;
  width: 100%;
  height: 5rem;
  display: grid;
  box-shadow: 5px 0 20px 5px #ebe6d9;
}

.logo {
  grid-column: 1 / 3;
  justify-content: start;
  align-items: center;
  display: grid;
}

footer {
  color: #fff;
  background-color: #000;
  margin-top: auto;
}

.address-input {
  width: 200px;
}

.sample {
  float: right;
  border: none;
  align-items: center;
  margin-top: 20px;
}

@media screen and (width <= 768px) {
  #contactPage-mainSection {
    flex-direction: "column";
  }

  #section-left, #section-right {
    width: "100%";
  }
}

/*# sourceMappingURL=index.1fbe1513.css.map */
